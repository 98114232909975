<template>
	<div class="notice">
		<div class="header">
			<div>{{columntitle}}></div>
			<div class="current">{{title}}</div>
		</div>
		<div class="horizontalline" v-if="date && date.length > 0"></div>
		
		<div v-if="template != 'channel'">
			<div class="content">
				<div class="specific">
					<ul>
						<li v-for="(item,index) in date" :key="index" v-if="template == 'date_list'">
							<div @click="jump(item.id)" style="height: 72PX;">
								<div class="news-date">
									<b>{{item.publishTime.split(" ")[0].split("-")[2]}}</b>
									<p>{{item.publishTime.split(" ")[0].split("-")[0]}}-{{item.publishTime.split(" ")[0].split("-")[1]}}
									</p>
								</div>
								<h2>
									{{item.title}}
								</h2>
								<p class="news-content">
									{{getText(item.content)}}
								</p>
							</div>
							<div class="horizontalline horizon"
								v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
						</li>
						<div v-if="template == 'picture'" style="display: flex;flex-wrap: wrap;">
							<div class="news_box" v-for="(item,index) in date" :key="index" v-if="template == 'picture'">
								<div class="news_item">
									<a @click="jump(item.id)">
										<img :src="item.imgUrl" v-if="item.imgUrl"/>
										<img src="../assets/image/placeholder.png" alt="" v-else>
										<h3>
											<div class="pic-title">
												{{item.title}}
											</div>
										</h3>
									</a>
								</div>
								<!-- <div class="horizontalline horizon" v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div> -->
							</div>
						</div>
						<!-- <li >
							
						</li> -->
						<li v-for="(item, index) in date" :key="index" class="nli" @click="jump(item.id)"
							v-if="template == 'picture_list'">
							<div>
								<div class="img" v-if="item.imgUrl">
									<img :src="item.imgUrl" alt="">
								</div>
								<div class="img" v-else>
									<img src="../assets/image/placeholder.png" alt="">
								</div>
								<h2 class="title2">
									{{item.title}}
								</h2>
								<div class="img_content">
									{{getText(item.content)}}
								</div>
								<span class="date">
									{{item.publishTime.split(" ")[0]}}
								</span>
							</div>
							<div style="margin-top: 43PX" class="horizontalline horizon"
								v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
						</li>
						<li v-for="(item,index) in date" :key="index" @click="jump(item.id)"
							v-if="template == 'common_list'">
							<div style="display: flex;justify-content: space-between;">
								<div class="rounddot">
									<div class="round"></div>
									<a class="roundtitle">
										{{item.title}}
									</a>
								</div>
								<span>[{{item.publishTime | format}}]</span>
							</div>
							<div class="horizontalline horizon"
								v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
						</li>
						<div v-if="template == 'picture_column'" style="display: flex;flex-wrap: wrap;">
							<div class="news_box" v-for="(item,index) in date" :key="index" v-if="template == 'picture_column'">
								<div class="news_item">
									<a @click="jump(item.id)">
										<img :src="item.imgUrl" v-if="item.imgUrl"/>
										<img src="../assets/image/placeholder.png" alt="" v-else>
										<h3>
											<div class="pic-title">
												{{item.title}}
											</div>
										</h3>
									</a>
								</div>
								<!-- <div class="horizontalline horizon" v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div> -->
							</div>
						</div>
						<li v-for="(item,index) in date" :key="index"
							v-if="template != 'picture_column' && template != 'common_list'&& template != 'picture_list'&& template != 'picture'&& template != 'date_list'">
							<div style="display: flex;justify-content: space-between;">
								<div class="rounddot">
									<div class="round"></div>
									<a class="roundtitle" @click="jump(item.id)">
										{{item.title}}
									</a>
								</div>
								<span>[{{item.publishTime | format}}]</span>
							</div>
							<div class="horizontalline horizon"
								v-if="date && date.length > 0 && Number.isInteger(Number(index + 1 ) / 5)"></div>
						</li>
					</ul>
				</div>
				<div class="horizontalline" v-if="date && date.length > 0 && !Number.isInteger(Number(date.length) / 5)">
				</div>
			</div>
			<div class="paging">
				<pagination :total="total" @currentchange="currentchange" :go="go" :pageSize="pageSize" :pageNo="pageNum"></pagination>
			</div>
		</div>
		<div v-else style="margin-left: 53PX">
			<div v-html="datedan.content || ''"></div>
			<div v-if="datedan.contentFileUrl && JSON.parse(datedan.contentFileUrl).length>0">
			
			  <ul>
			    <li v-for="(item,index) in JSON.parse(datedan.contentFileUrl)" :key="index" style="margin-bottom: 10PX"><a :href="item.url" style="color: #2d52a0">
			      <img src="/images/fujian.png" alt="" style="width: 20PX">
			      {{item.name}}
			    </a></li>
			  </ul>
			</div>
		</div>

	</div>
</template>
<script>
	import Vue from 'vue'
	import Meta from 'vue-meta'
	Vue.use(Meta)
	export default {
		name: 'notice',
		components: {},
		data() {
			return {
				id: 718,
				pageNum: 1,
				pageSize: 20,
				total: 0,
				date: [],
				template: '',
				title: '',
				columntitle:'',
				datedan:{},
				go:20
			}
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		created() {
			this.findAllColumn()
		},
		methods: {
			findAllColumn() {
				let data = {
					id: this.$route.query.id,
					pid: this.$route.query.pid
				}
				this.$api.findAllColumn(data).then(res => {
					this.columntitle = res.data.data.list[0].name
					this.title = res.data.data.pid.name
					
					document.title = res.data.data.pid.name + '-北京市体育竞赛管理和国际交流中心'
					this.$meta().refresh()
					
					this.template = res.data.data.pid.template
					if(this.template == 'picture' || this.template == 'picture_column'){
						this.pageSize = 21,
						this.go = 21
					}else{
						this.pageSize = 20,
						this.go = 20
					}
					
					if(this.template == 'channel'){
						this.getArticleById()
					}else{
						this.getArticleListById()
					}
				})
			},
			currentchange(val) {
				this.pageNum = val
				this.getArticleListById()
			},
			getArticleListById() {
				let data = {
					id: this.$route.query.id,
					pageNum: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.getArticleListById(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.list
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			getArticleById(){
				this.$api.getArticleById({id:this.$route.query.id}).then(res=>{
					this.datedan = res.data.data
				})
			},
			jump(id) {
				if(this.$route.query.id == 728 && this.$route.query.pid == 847){
					if (this.$store.state.token == '') {
						this.$confirm('请登录后查看更多信息', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$store.commit("EXIT_TOKEN")
							this.$router.push({
								path: '/login',
								query: {
									person: true,
									grp: false,
									refresh: new Date().getTime()
								}
							})
						}).catch(() => { });
						return
					}
				}
				let routeData = this.$router.resolve({
					path: '/articleDetails',
					query: {
						id: id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getText(str) {
				if(str == null || str == '') return
				let hasChineseFontFamily = /font-family:\s*[^;]*[\u4E00-\u9FFF]+/g.test(str)
				if (hasChineseFontFamily) {
				  str = str.replace(/font-family:\s*[^;]*[\u4E00-\u9FFF]+/g, "font-family: ''");
				}
				let res = str.replace(/[^\u4e00-\u9fa5]/gi, "").replace("微软雅黑", "")
				if (res.length > 80) {
					res = res.slice(0, 80) + '...'
				}
				return res
			},
		}
	}
</script>
<style lang="scss" scoped>
	.notice {
		width: 1200PX;
		margin: 0 auto;
	}

	.header {
		display: flex;
		margin-top: 34PX;
		margin-left: 63PX;
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-bottom: 23PX;
		cursor: pointer;
	}

	.current {
		color: #0066FF;
	}

	.content {
		width: 1200PX;
		margin: 0 auto;
	}

	.horizontalline {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
		margin-left: 53PX;
	}

	.specific {
		margin-left: 53PX;
		font-size: 15PX;
		color: #333333;
		cursor: pointer;
	}

	.specific ul li {

		margin-bottom: 23PX;
	}

	.rounddot {
		display: flex;
		align-items: center
	}

	.round {
		width: 7PX;
		height: 7PX;
		border-radius: 100%;
		background: #000;
		box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
		margin-right: 10PX;
	}

	.roundtitle {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 1000PX;
		cursor: pointer;
	}

	.paging {
		margin-top: 81PX;
		margin-bottom: 132PX;
		display: flex;
		margin-left: 53PX;
		align-items: center;
	}

	.paging span {
		margin-right: 5PX;
	}

	::v-deep .el-pager li.active {
		color: #003399;
	}

	.news-date {
		width: 72PX;
		height: 72PX;
		border: 1PX solid #bfd4e9;
		float: left;
		margin-right: 30PX;
		font-family: arial;
		color: #6198e2;
		background: #fff;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.news-date b {
		height: 43PX;
		line-height: 42PX;
		font-size: 26PX;
	}

	.news-date p {
		font-size: 14PX;
		margin: 0 5PX;
		height: 26PX;
		line-height: 26PX;
		color: #6198e2;
		background: #fff;
		border-top: 1PX solid #dfe7f3;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}

	.specific li h2 {
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		height: 22PX;
		line-height: 22PX;
		margin-bottom: 8PX;
		margin-top: 0;
	}

	.specific li:hover a {
		color: #408ED6;
	}

	.specific li:hover .news-date {
		border: 1PX solid #408ED6;
		background: #408ED6;
		color: #fff;
	}

	.specific li:hover .news-date p {
		color: #fff;
		background: #408ED6;
	}

	.news_item {
		width: 362PX;
		margin-top: 28PX;
		margin-right: 30PX;
	}
	.news_box:nth-child(3n) .news_item{
		margin-right: 0;
	}

	.news_item img {
		width: 100%;
		height: 229PX;
	}

	.news_item h3 {
		font-size: 14PX;
		height: 54PX;
		line-height: 54PX;
		font-weight: 400;
		text-align: center;
		overflow: hidden;
	}

	.news_item:nth-child(3n) {
		margin-right: 0;
	}

	a:hover {
		color: #408ED6;
	}

	.nli {
		margin: 30PX 18PX 0;
		overflow: hidden;
		padding-bottom: 30PX;
		display: block !important;
	}

	.img {
		width: 200PX;
		height: 133PX;
		float: left;
		margin-right: 30PX;
		background: #fff;
	}

	.img img {
		margin: 0 auto;
		width: 200PX;
		height: 133PX;
		border: 0;
		display: block;
	}

	.title2 {
		height: 30PX;
		line-height: 30PX;
		overflow: hidden;
		font-size: 16PX;
		color: #333;
		font-weight: 700;
		margin-bottom: 10PX;
		margin-top: 5PX;
	}

	.date {
		color: #999;
		display: block;
		padding: 10PX 0 0;
	}

	.img_content {
		height: 40PX;
		overflow: hidden;
	}

	ul {
		margin-bottom: 30PX;
	}

	.news-text li:hover h2 {
		color: #408ED6;
	}

	h2 {
		cursor: pointer;
	}

	.pic_item {
		width: 33.3%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 20PX;
	}

	.item-box {
		width: 317PX;
		height: 257PX;
	}

	.pic_title {
		text-align: center;
		margin-top: 20PX;
		cursor: pointer;
		font-size: 16PX;
	}

	.horizon {
		border: 1PX dashed #BFBFBF;
		margin-bottom: 23PX;
		margin-top: 23PX;
		padding: 0 53PX;
		margin-left: 0;
	}
</style>